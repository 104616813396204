import { Grid } from "@material-ui/core"
import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"
import * as React from "react"
import { ContactUsContainer } from "../../containers/ContactUsContainer.js"
import { makeStyles } from "@material-ui/core/styles"

const ReadyToLearn = () => {
  const classes = useStyles()
  return <SectionP moreClasses={classes.section}>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">READY TO LEARN?</SectionTitle>
      </Grid>
      <Grid item xs={9} md={6} style={{ border: "0px solid red" }}>
        <ContactUsContainer label="CONTACT US" isFullWidth={true}/>
      </Grid>
    </Grid>
  </SectionP>
}

const useStyles = makeStyles(theme => ({
  section: {
    position: "relative",
    backgroundColor: theme.palette.secondary["surface"],
  },
}))

export default ReadyToLearn