import { Grid } from "@material-ui/core"
import * as React from "react"
import { mentoringReviews } from "../../shared/reviews-data"
import Praises from "../../components/Praises"
import HowItWorks from "../../components/Mentoring/HowItWorks"
import ProgramCosts from "../../components/Mentoring/ProgramCost"
import FAQsSection from "../../components/Mentoring/FAQsSection"
import ReadyToLearn from "../../components/Mentoring/ReadyToLearn"
import PageTitleDescription from "../Common/PageTitleDescription"
import Instructors from "./Instructors"
import WhatYouWillLearn from "./WhatYouWillLearn"

interface OfferingProps {
  title: string;
  instructors: any[];
  topics: string[];
}

const MentoringOffering = ({ title, instructors, topics }: OfferingProps) => (
  <Grid container justify="center">
    <Grid item xs={12}>
      <PageTitleDescription title={title}/>
    </Grid>
    <Grid item xs={12}>
      <WhatYouWillLearn topics={topics}/>
    </Grid>
    <Grid item xs={12}>
      <Praises data={mentoringReviews} auto={false}/>
    </Grid>
    <Grid item xs={12}>
      <Instructors members={instructors}/>
    </Grid>
    <Grid item xs={12}>
      <HowItWorks/>
    </Grid>
    <Grid item xs={12}>
      <ProgramCosts/>
    </Grid>
    <Grid item xs={12}>
      <FAQsSection/>
    </Grid>
    <Grid item xs={12}>
      <ReadyToLearn/>
    </Grid>
  </Grid>
)

export default MentoringOffering
