import { Grid, Typography } from "@material-ui/core"
import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"
import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Check } from "@material-ui/icons"

interface LearningPointProps {
  topics: string[];
}

const WhatYouWillLearn = ({ topics }: LearningPointProps) => {
  const classes = useStyles()

  return <SectionP>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">WHAT YOU WILL LEARN</SectionTitle>
      </Grid>
      <Grid item xs={9} style={{ border: "0px solid red" }}>
        <Grid container direction={"column"} justify={"flex-start"}>
          {
            topics.map((topic) => <Grid item key={topic} className={classes.topic}>
                <Typography paragraph variant={"body1"}><Check className={classes.check}/>{topic}</Typography>
              </Grid>,
            )}
        </Grid>
      </Grid>
    </Grid>
  </SectionP>
}

const useStyles = makeStyles(theme => ({
  topic: {
    paddingTop: "none",
  },
  check: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
}))


export default WhatYouWillLearn