import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import MentoringOffering from "../components/Mentoring/MentoringOffering"
import { TeamMembersData } from "../shared/constants"
import { PageTitles } from "../shared/constants"

export default class ReactRedux extends React.Component {
  render() {
    const seo = seoData.reactRedux
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={false}>
          <MentoringOffering title={PageTitles.reactRedux.title} instructors={TeamMembersData} topics={topics}/>
        </Layout>
      </>
    )
  }
}

const topics = [
  "Hands-on experience with JSX syntax",
  "Working with data in components using State",
  "Working with Hooks such as useState, useEffect, useRef, and more",
  "Learning the core concepts behind Redux",
  "Working with Actions, Action Creators, Reducers, and Dispatchers",
  "Learning to make trade-offs between the use of React State and Redux",
  "Using middlewares and persisting state in browser's storage",
  "Working with side-effects such as API calls using thunks",
  "Using Redux dev tools for debugging and time-traveling",
  "Testing Redux Actions, Action Creators, and Reducers",
]
