import { Grid } from "@material-ui/core"
import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"
import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"
import TeamMembers from "../Team/TeamMembers"

const Instructors = ({members}) => {
  const classes = useStyles()
  return <SectionP moreClasses={classes.section}>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">Instructors</SectionTitle>
      </Grid>
      <Grid item xs={10} style={{ border: "0px solid red" }}>
        <TeamMembers members={members}/>
      </Grid>
    </Grid>
  </SectionP>
}

const useStyles = makeStyles(theme => ({
  section: {
    position: "relative",
    backgroundColor: theme.palette.secondary["surface"],
  },
}))

export default Instructors