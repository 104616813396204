import { Grid } from "@material-ui/core"
import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"
import Faqs from "../CourseDetail/CourseDetailTabs/FaqsTab"
import * as React from "react"

const FAQsSection = () => {
  return <SectionP>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">FAQs</SectionTitle>
      </Grid>
      <Grid item xs={9} md={6} style={{ border: "0px solid red" }}>
        <Faqs items={QnAs}/>
      </Grid>
    </Grid>
  </SectionP>
}

const QnAs = [
  {
    "question": "Can I take one session?",
    "answer": "Yes, we charge USD 120 for a single session.",
  },
  {
    "question": "When does the program start?",
    "answer": "We work with 2 mentees each month. Depending on your needs and our schedule we can work with the start time fo your mentoring. Reach us out to know our current availability.We work with two mentees each month. Depending on your needs and our schedule, we can work with the start time for your mentoring. Reach out to know our current availability.",
  },
  {
    "question": "Do you offer parity pricing and discounts to students?",
    "answer": "Yes, we offer 30% off on our session price. We, however, require some documentation before offering the discount. Reach us, and we would be happy to help you.",
  },
  {
    "question": "How often are sessions scheduled in a week?",
    "answer": "We have witnessed better learning rates when sessions are scheduled twice a week. However, we do understand different needs and try our best to address your requirements.",
  },
  {
    "question": "How are payments made?",
    "answer": "Your payments are 100% secure. We use Stripe to collect our payments. It is the same company used by other companies such as Lyft, Instacart, Salesforce, Shopify, and many others. You get the invoice from us and receive the receipts on payment automatically.",
  },
]

export default FAQsSection