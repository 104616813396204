import { Grid, Typography } from "@material-ui/core"
import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"
import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"

const HowItWorks = () => {
  return <SectionP>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">HOW IT WORKS</SectionTitle>
      </Grid>
      <Grid item xs={9} style={{ border: "0px solid red" }}>
        <Grid container direction={"column"} justify={"flex-start"}>
          {
            Steps.map((step, num) => <Grid item key={step}>
                <DisplayStep number={num + 1} step={step}/>
              </Grid>,
            )}
        </Grid>
      </Grid>
    </Grid>
  </SectionP>
}

const DisplayStep = ({ number, step }) => {
  const classes = useStyles()

  return <Typography variant={"subtitle1"} className={classes.step}>
    <span className={classes.number}>{number}</span>{step}
  </Typography>
}

const useStyles = makeStyles(theme => ({
  step: {
    marginTop: theme.spacing(4),
  },

  number: {
    // inspired from https://codepen.io/braddalton/pen/oKrFg
    backgroundColor: theme.palette.secondary[400],
    borderRadius: "50%",
    lineHeight: "3em",
    width: "3em",
    display: "inline-block",
    fontWeight: "bold",
    textAlign: "center",
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      lineHeight: "2em",
      width: "2em",
      marginRight: theme.spacing(1),
    },
  },
}))

const Steps = [
  "You select the program",
  "We meet for 15-20 minutes to learn about your learning goals",
  "You pay for the sessions. A minimum of 5 sessions are required",
  "We prepare your personalized learning path. This does not add any additional cost to you",
  "We meet online as per the agreed schedule and start your learning journey",
]

export default HowItWorks