import { Grid, Typography } from "@material-ui/core"
import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"
import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"

const ProgramCosts = () => {
  const classes = useStyles()

  return <SectionP moreClasses={classes.section}>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">PROGRAM COST</SectionTitle>
      </Grid>
      <Grid item xs={9} md={6} style={{ border: "0px solid red" }}>
        <Grid container direction={"column"} justify={"flex-start"}>
          {
            Paragraphs.map(paragraph => <Grid item key={paragraph}>
                <DisplayParagraph paragraph={paragraph} classes={classes}/>
              </Grid>,
            )}
        </Grid>
      </Grid>
    </Grid>
  </SectionP>
}

const DisplayParagraph = ({ paragraph, classes }) => {
  return <Typography variant={"body1"} className={classes.step}>
    {paragraph}
  </Typography>
}

const useStyles = makeStyles(theme => ({
  section: {
    position: "relative",
    backgroundColor: theme.palette.secondary["surface"],
  },
  step: {
    marginTop: theme.spacing(4),
  },
}))

const Paragraphs = [
  "We charge USD 80 per hour. We call this a session. You must buy at least 5 sessions since most of our programs take at least those many sessions.",
  "There is much work we do in the background to support you. Creating quizzes and projects with specific learning-based outcomes is not charged as an additional cost to you as a mentee.",
]

export default ProgramCosts