import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

export const Faqs = ({ classes, items }) => (
  <Grid container spacing={2}>
    {items.map((item, i) => (
      <Grid item xs={12} key={i}>
        <Faq question={item.question} answer={item.answer} classes={classes} />
      </Grid>
    ))}
  </Grid>
);

const styles = theme => ({
  questionFont: {
    fontWeight: theme.typography.fontWeightMedium
  }
});

export default withStyles(styles)(Faqs);

const Faq = ({ classes, question, answer }) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant={'subtitle1'} className={classes.questionFont}>{question}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant={'body1'} color={'textSecondary'}>
        {answer}
      </Typography>
    </Grid>
  </Grid>
);
